import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import styled from 'styled-components';
import { Content } from 'components/atoms/Content';
import { Navigation } from 'swiper';

import 'swiper/css';
import { IconButton } from 'components/atoms/IconButton';
import leftImage from 'public/icons/control/chevron-left.svg';
import rightImage from 'public/icons/control/chevron-right.svg';
import startImage from 'public/icons/control/star.svg';
import Image from 'next/image';
import { Breakpoints } from 'styles/theme';
import { useTranslation } from 'next-i18next';

type Testimonial = {
    author: string;
    text: string;
};

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    align-items: center;

    h2 {
        font-size: 40px;
    }

    p {
        font-size: 20px;
        text-align: center;
        max-width: 960px;
    }
`;

const ItemActions = styled.div`
    width: 70px;
    display: flex;
    justify-content: space-between;

    @media ${Breakpoints.xl} {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 50%;

        img {
            width: 20px;
            height: 40px;
        }
    }
`;

const Rating = styled.div`
    display: flex;
    width: 165px;
    justify-content: space-between;
    margin-bottom: 1.5rem;
`;

const TestimonialRenderer = ({ testimonial }: { testimonial: Testimonial }) => {
    const swiper = useSwiper();
    return (
        <ItemContainer>
            <h2>{testimonial.author}</h2>
            <Rating>
                {Array(5)
                    .fill(0)
                    .map((_, index) => index)
                    .map((x) => (
                        <Image key={x} src={startImage} alt="star" />
                    ))}
            </Rating>
            <ItemActions>
                <IconButton source={leftImage} onClick={() => swiper.slidePrev()} />
                <IconButton source={rightImage} onClick={() => swiper.slideNext()} />
            </ItemActions>
            <p>{testimonial.text}</p>
        </ItemContainer>
    );
};

export const TestimonialsSection = () => {
    const { t } = useTranslation('home');
    const testimonials = t('testimonials', { returnObjects: true }) as Testimonial[];
    return (
        <div>
            <Content horizontal>
                <Swiper slidesPerView={1} modules={[Navigation]} loop>
                    {testimonials.map((x) => (
                        <SwiperSlide key={x.author}>
                            <TestimonialRenderer testimonial={x} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Content>
        </div>
    );
};
